import React from "react";
import './Aim.css'
const Aim = () => { 
  return (
    <div  id = "aim-page" className='dark'>
     <div id = "nav-placeholder" />
      <div id="container">
        <div className="section">
          <h1>
           AIM
          </h1>
         <p> Founded in 2017,CSD Robocon NITK is the college's official robotics team . As a group of 30 interdisciplinary undergraduates,we aim to win the grandprix at prestigious ABU Robocon
         </p>
          </div>
        </div>
      </div>
  );
};
  
export default Aim;


